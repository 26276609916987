import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "../common/Text";
import { useUserContext } from "../user/UserContext";

interface LocationAnchorProps {
  isSidebarVisible: boolean;
  toggleSidebar: () => void;
}

export default function LocationAnchor({
  isSidebarVisible,
  toggleSidebar,
}: LocationAnchorProps) {
  const { user } = useUserContext();
  const hasUser = user != null;
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        "col-span-6 row-span-1 row-start-1",
        hasUser && "md:col-span-5 md:row-span-1 md:row-start-1",
        !hasUser && "md:col-span-6 md:row-span-1 md:row-start-1",
        "ml-2 my-2"
      )}
    >
      <div className="flex flex-1 flex-row gap-1 self-start items-center h-full">
        {/* <Button
          onClick={toggleSidebar}
          value=""
          icon={
            isSidebarVisible ? (
              <PanelLeftClose className="w-4 h-4" />
            ) : (
              <PanelLeftOpen className="w-4 h-4" />
            )
          }
          size="icon"
          variant="flatDark"
        /> */}
        <button
          onClick={() => navigate("/")}
          value="Iași Orașul Nostru"
          className={classNames(
            "rounded-md hover:bg-gray-400/10 py-1 px-3 transition-colors duration-300"
          )}
        >
          <div className="flex flex-col items-start">
            <Text color="white" size="h2" className="">
              Iași
            </Text>
            <Text color="text-gray-300" className="font-bold">
              Orașul Nostru
            </Text>
          </div>
        </button>
      </div>
    </div>
  );
}
