import React from "react";

export interface TextProps {
  children: React.ReactNode;
  emphasized?: boolean;
  color?: string;
  size?: keyof typeof textSizes;
  className?: string;
  display?: "inline-block" | "block" | null;
  style?: React.CSSProperties;
}

export const textColors: Record<string, string> = {
  white: "text-white",
  black: "text-black",
  default: "text-text",
  secondary: "text-secondary",
  secondaryDark: "text-secondary-dark",
  inherit: "text-inherit",
  foreground: "text-secondary-foreground",
  amber: "text-amber-700",
  blue: "text-blue-700",
  red: "text-red-700",
  green: "text-green-700",
};

const textSizes = {
  h1: "text-4xl",
  h2: "text-3xl",
  h3: "text-2xl",
  h4: "text-xl",
  h5: "text-lg",
  base: "text-base",
  small: "text-sm",
  tiny: "text-xs",
  micro: "text-xxs",
};

export function Text({
  children,
  emphasized = false,
  color = "default",
  size = "base",
  display = "inline-block",
  style,
  className,
}: TextProps) {
  const classNames = [
    display ?? "",
    emphasized ? "font-semibold" : "",
    textColors[color] == null ? color : textColors[color],
    textSizes[size],
    className,
  ];

  return (
    <p className={classNames.join(" ")} style={style}>
      {children}
    </p>
  );
}
