import React, { ReactNode } from "react";
import { Text } from "../common/Text";

type WidgetHeaderProps = {
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  color?: string;
};

export function WidgetHeader({
  children,
  className,
  icon,
  color,
}: WidgetHeaderProps) {
  return (
    <div className="flex flex-row gap-1 items-center m-1">
      {icon}
      <Text className={className} color={color} emphasized>
        {children}
      </Text>
    </div>
  );
}
