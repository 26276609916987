import { MessageSquareWarningIcon } from "lucide-react";
import React from "react";
import { WidgetHeader } from "./WidgetHeader";

export default function PublicAnnouncements() {
  return (
    <div className="col-span-full md:col-span-6 row-span-3 bg-blue-50/20 md:bg-white hover:bg-blue-50/20 border border-blue-200 hover:border-blue-200 md:border-gray-200 rounded-md on-widget">
      <WidgetHeader icon={<MessageSquareWarningIcon className="w-4 h-4" />}>
        Informatii Locale
      </WidgetHeader>
    </div>
  );
}
