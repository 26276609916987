import * as React from "react";
import { format } from "date-fns";
import { Popover, PopoverTrigger, PopoverContent } from "./Popover";
import { CalendarIcon } from "lucide-react";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { ro } from "date-fns/locale";

type DatePickerProps = {
  date?: Date;
  setDate: (date: Date | undefined) => void;
  placeholder?: string;
  fromDate?: Date;
};

export function DatePicker({
  date,
  setDate,
  placeholder = "Selecteaza data",
  fromDate,
}: DatePickerProps) {
  const [open, setOpen] = React.useState(false);
  return (
    <Popover open={open} onOpenChange={() => setOpen(!open)}>
      <PopoverTrigger asChild>
        <Button
          variant={"outlineLight"}
          className={[
            "self-start justify-start text-left font-normal",
            !date ? "text-gray-500" : "",
          ].join(" ")}
          value={
            date != null ? format(date, "PPPP", { locale: ro }) : placeholder
          }
          icon={<CalendarIcon className="h-4 w-4" />}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 bg-white" align="start">
        <Calendar
          required={true}
          mode="single"
          selected={date}
          fromDate={fromDate}
          onSelect={newDate => {
            setDate(newDate);
            setOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
