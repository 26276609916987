import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import classNames from "classnames";
import { ChevronLeftIcon, ChevronRightIcon, ImageIcon } from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button } from "../../common/Button";
import { Text } from "../../common/Text";

type MediaViewProps = {
  media: OnMedia[];
};

export function MediaView({ media }: MediaViewProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePrevImage = useCallback(() => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  }, [currentImageIndex]);

  const handleNextImage = useCallback(() => {
    if (currentImageIndex < media.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  }, [currentImageIndex, media.length]);

  const handleImageClick = () => {
    setIsPopoverOpen(true);
  };

  const previousImageButton = useMemo(() => {
    return (
      <Button
        icon={<ChevronLeftIcon className="w-6 h-6 text-secondary" />}
        value=""
        variant="outlineLight"
        size="icon"
        onClick={handlePrevImage}
        disabled={currentImageIndex === 0}
        className={"m-2"}
      />
    );
  }, [currentImageIndex, handlePrevImage]);

  const nextImageButton = useMemo(() => {
    return (
      <Button
        icon={<ChevronRightIcon className="w-6 h-6 text-secondary" />}
        value=""
        variant="outlineLight"
        size="icon"
        onClick={handleNextImage}
        disabled={currentImageIndex === media.length - 1}
        className={"m-2"}
      />
    );
  }, [currentImageIndex, handleNextImage, media.length]);

  const hasImages = media.length > 0;

  return (
    <div>
      <div
        className={classNames(
          "w-full shrink-0 flex flex-row items-center px-2 pt-4 relative",
          hasImages ? "justify-between" : "justify-center"
        )}
      >
        {hasImages ? (
          <>
            {previousImageButton}
            <AlertDialog open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
              <AlertDialogTrigger asChild>
                <img
                  className={classNames(
                    "max-w-[80%]",
                    "max-h-[240px]",
                    "rounded-md border border-slate-200 shadow-lg cursor-pointer"
                  )}
                  src={media[currentImageIndex].url}
                  alt={media[currentImageIndex].name}
                  style={{ objectFit: "contain" }}
                  onClick={handleImageClick}
                />
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay onClick={() => setIsPopoverOpen(false)} />
                <AlertDialogPrimitive.Content className="fixed left-[50%] top-[50%] z-50 grid translate-x-[-50%] translate-y-[-50%] bg-white p-2 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-lg">
                  <Button
                    icon={<ChevronLeftIcon className="w-10 h-10 text-white" />}
                    value=""
                    variant="none"
                    size="none"
                    onClick={handlePrevImage}
                    disabled={currentImageIndex === 0}
                    className={classNames(
                      "absolute left-[-10vw] top-1/2 transform -translate-y-1/2 h-12 w-12",
                      {
                        "hover:bg-white/30": currentImageIndex > 0,
                      }
                    )}
                  />
                  <img
                    className="rounded-md border border-slate-200"
                    src={media[currentImageIndex].url}
                    alt={media[currentImageIndex].name}
                    style={{
                      objectFit: "contain",
                      maxHeight: "90vh",
                      maxWidth: "70vw",
                    }}
                  />
                  <Button
                    icon={<ChevronRightIcon className="w-10 h-10 text-white" />}
                    value=""
                    variant="none"
                    size="none"
                    onClick={handleNextImage}
                    disabled={currentImageIndex === media.length - 1}
                    className={classNames(
                      "absolute right-[-10vw] top-1/2 transform -translate-y-1/2 h-12 w-12",
                      {
                        "cursor-not-allowed text-gray-400":
                          currentImageIndex === media.length - 1,
                        "hover:bg-white/30":
                          currentImageIndex < media.length - 1,
                      }
                    )}
                  />
                </AlertDialogPrimitive.Content>
              </AlertDialogPortal>
            </AlertDialog>
            {nextImageButton}
          </>
        ) : (
          <ImageIcon className="w-32 h-32 text-secondary/20" />
        )}
      </div>
      <div className="flex justify-center w-full mt-1">
        {hasImages && (
          <Text className="italic" size="small" color="secondary">
            {currentImageIndex + 1} / {media.length}
          </Text>
        )}
      </div>
    </div>
  );
}

export type OnMedia = {
  added: Date;
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
};
