import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { FileWithPreview } from "../../common/ImageUpload";
import type { OnEntitySearchResult } from "../Entity/entitiesClient";
import type { EventLinkWithCategory } from "./EventEditingPage";
import { type CreateOnEventRequestPayload } from "./eventsClient";
import { useEventsContext } from "./EventsContext";
import type { OnMedia } from "./MediaView";
import type { OnEvent } from "./OnEvent";

interface EventEditingContextData {
  organizer: OnEntitySearchResult | undefined;
  setOrganizer: (organizer: OnEntitySearchResult | undefined) => void;

  name: string;
  setName: (name: string) => void;
  oneLine: string;
  setOneLine: (oneLine: string) => void;
  description: string;
  setDescription: (description: string) => void;
  startDate: Date | undefined;
  setStartDate: (startDate: Date | undefined) => void;
  endDate: Date | undefined;
  setEndDate: (endDate: Date | undefined) => void;
  address: string;
  setAddress: (address: string) => void;
  locationOnMap: string;
  setLocationOnMap: (locationOnMap: string) => void;
  links: EventLinkWithCategory[];
  setLinks: (links: EventLinkWithCategory[]) => void;
  hasDraftState: boolean;
  isCreationDisabled: boolean;
  status: OnEvent["status"];
  setStatus: (status: OnEvent["status"]) => void;

  existingImages: OnMedia[];
  finalImagesOrder: string[]; // names
  imagesToUpload: FileWithPreview[];
  imagesToDelete: string[]; // ids
  setImages: (images: FileWithPreview[]) => void;

  saveEvent: () => Promise<string | undefined | null>;
}

export const EventEditingContext =
  createContext<EventEditingContextData | null>(null);

interface EventEditingContextProviderProps {
  eventToEdit?: OnEvent;
  children: React.ReactNode;
  setUpdatedEvent?: (event: OnEvent) => void;
}

function EventEditingContextProviderInternal({
  children,
  eventToEdit,
  setUpdatedEvent,
}: EventEditingContextProviderProps) {
  const { createEvent, updateEvent, updateMedia } = useEventsContext();

  const editedEventId = eventToEdit?.id;
  const existingImages = useMemo(
    () => eventToEdit?.media ?? [],
    [eventToEdit?.media]
  );
  const [organizer, setOrganizer] = useState<OnEntitySearchResult | undefined>(
    eventToEdit?.organizer != null ? eventToEdit.organizer : undefined
  );

  const [finalImagesOrder, setFinalImagesOrder] = useState<string[]>(
    existingImages.map(media => media.name)
  );
  const [imagesToUpload, setImagesToUpload] = useState<FileWithPreview[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);

  const setImages = useCallback(
    (images: FileWithPreview[]) => {
      const newImagesOrder = images.map(image => image.name);
      setFinalImagesOrder(newImagesOrder);
      const newImages = images.filter(
        image =>
          !existingImages.some(
            existingImage => existingImage.name === image.name
          )
      );
      setImagesToUpload(newImages);
      setImagesToDelete(
        existingImages
          .filter(existingImage => !newImagesOrder.includes(existingImage.name))
          .map(media => media.id)
      );
    },
    [existingImages]
  );

  const [status, setStatus] = useState<OnEvent["status"]>(
    eventToEdit?.status ?? "Draft"
  );
  const [name, setName] = useState(eventToEdit?.name ?? "");
  const [oneLine, setOneLine] = useState(eventToEdit?.oneLine ?? "");
  const [description, setDescription] = useState(
    eventToEdit?.description ?? ""
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    eventToEdit?.startDate ?? undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    eventToEdit?.endDate ?? undefined
  );
  const [address, setAddress] = useState(eventToEdit?.address ?? "");
  const [locationOnMap, setLocationOnMap] = useState(
    eventToEdit?.locationOnMap ?? ""
  );

  const [links, setLinks] = useState<EventLinkWithCategory[]>(
    eventToEdit?.links ?? []
  );

  const hasDraftState = useMemo(() => {
    if (editedEventId != null && eventToEdit != null) {
      return (
        status != (eventToEdit.status ?? "Draft") ||
        name !== eventToEdit.name ||
        oneLine !== eventToEdit.oneLine ||
        description !== eventToEdit.description ||
        startDate !== eventToEdit.startDate ||
        endDate !== eventToEdit.endDate ||
        address !== eventToEdit.address ||
        locationOnMap !== eventToEdit.locationOnMap ||
        links.length !== eventToEdit.links.length ||
        links.some(
          ({ category, url }, index) =>
            category !== eventToEdit.links[index].category ||
            url !== eventToEdit.links[index].url
        ) ||
        imagesToUpload.length > 0 ||
        imagesToDelete.length > 0 ||
        finalImagesOrder.length !== existingImages.length ||
        finalImagesOrder.some(
          (image, index) => image !== existingImages[index].name
        )
      );
    }

    return (
      name.length > 0 ||
      oneLine.length > 0 ||
      description.length > 0 ||
      startDate !== undefined ||
      endDate !== undefined ||
      address.length > 0 ||
      locationOnMap.length > 0 ||
      links.filter(
        ({ category, url }) => category !== undefined || url.length > 0
      ).length > 0
    );
  }, [
    address,
    description,
    editedEventId,
    endDate,
    eventToEdit,
    existingImages,
    finalImagesOrder,
    imagesToDelete.length,
    imagesToUpload.length,
    links,
    locationOnMap,
    name,
    oneLine,
    startDate,
    status,
  ]);

  const isCreationDisabled =
    name.length === 0 || startDate === undefined || address.length === 0;

  const prepareAndUpdateMedia = useCallback(
    async (eventId: string) => {
      let newMedia: null | FormData = null;
      if (imagesToUpload.length > 0) {
        newMedia = new FormData();
        Array.from(imagesToUpload).forEach(file => {
          newMedia!.append("files", file);
        });
      }
      const event = await updateMedia(eventId, {
        newMedia,
        mediaToDelete: imagesToDelete,
        mediaOrder: [],
      });
      console.log({ event });
    },
    [imagesToDelete, imagesToUpload, updateMedia]
  );

  const saveEvent = useCallback(async () => {
    if (name.length === 0 || startDate === undefined || address.length === 0) {
      console.error("Start date is required");
      return;
    }

    const eventPayload = {
      status,
      organizerId: organizer?.id,
      name,
      oneLine,
      description,
      startDate,
      endDate,
      address,
      locationOnMap,
      links,
      // hardcodeded values
      city: "Iasi",
      access: "Public",
      accessMethod: "Public",
      accessRequest: [],
      categories: [],
      instance: "Instance",
    } satisfies CreateOnEventRequestPayload;
    if (editedEventId != null) {
      const event = await updateEvent(editedEventId, eventPayload);
      await prepareAndUpdateMedia(editedEventId);
      setUpdatedEvent?.(event);
      return event.id;
    }
    const eventId = await createEvent(eventPayload);
    return eventId;
  }, [
    address,
    createEvent,
    description,
    editedEventId,
    endDate,
    links,
    locationOnMap,
    name,
    oneLine,
    organizer?.id,
    prepareAndUpdateMedia,
    setUpdatedEvent,
    startDate,
    status,
    updateEvent,
  ]);

  const value = useMemo(() => {
    return {
      organizer,
      setOrganizer,

      name,
      setName,
      oneLine,
      setOneLine,
      description,
      setDescription,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      address,
      setAddress,
      locationOnMap,
      setLocationOnMap,
      links,
      setLinks,
      hasDraftState,
      isCreationDisabled,

      status,
      setStatus,

      saveEvent,

      existingImages,
      finalImagesOrder,
      imagesToUpload,
      imagesToDelete,
      setImages,
    } satisfies EventEditingContextData;
  }, [
    organizer,
    name,
    oneLine,
    description,
    startDate,
    endDate,
    address,
    locationOnMap,
    links,
    hasDraftState,
    isCreationDisabled,
    status,
    saveEvent,
    existingImages,
    finalImagesOrder,
    imagesToUpload,
    imagesToDelete,
    setImages,
  ]);

  return (
    <EventEditingContext.Provider value={value}>
      {children}
    </EventEditingContext.Provider>
  );
}

export const EventEditingContextProvider = React.memo(
  EventEditingContextProviderInternal
);

export function useEventEditingContext() {
  const context = useContext(EventEditingContext);
  if (!context) {
    throw new Error(
      "useEventEditingContext must be used within a EventEditingContextProvider"
    );
  }
  return context;
}
