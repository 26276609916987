import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useSetSearchParam } from "../../utils/useSetSearchParam";
import { transportClient } from "./transportClient";
import { useTransportMetadataContext } from "./TransportMetadataContext";

export enum StopStatusDetails {
  NewOnRoute = "new-on-route", // Vehicle is new on the route, waitig at the first stop of the trip
  // Add other statuses as needed
}

const STOP_ID_SEARCH_PARAM = "stopId";

export type TransportStopStatus = {
  createdAt: Date;
  updatedAt: Date;
  agencyId: string;
  vehicleId: number;
  targetStopId: number;
  remainingStops: number;
  tripId: string;
  routeId: number;
  arrivalStatus: string;
  vehicleType: number;
  details?: StopStatusDetails;
};

interface TransportStopStatusContextData {
  selectedStopId: number;
  stopStatuses: TransportStopStatus[];
  setSelectedStopId: (stopId: number) => void;
  refreshStopStatuses: () => void;
}

export const TransportStopStatusContext =
  createContext<TransportStopStatusContextData | null>(null);

interface TransportStopStatusContextProviderProps {
  children: React.ReactNode;
}

function TransportStopStatusContextProviderInternal({
  children,
}: TransportStopStatusContextProviderProps) {
  const { agencyId } = useTransportMetadataContext();
  const stopIdFromParams = useStopIdFromSearchParams();
  const setSearchParams = useSetSearchParam();
  const [selectedStopId, setSelectedStopId] = useState(stopIdFromParams ?? 7);
  const setSelectedStopIdCallback = useCallback(
    (stopId: number) => {
      setSearchParams(STOP_ID_SEARCH_PARAM, stopId.toString());
      setSelectedStopId(stopId);
    },
    [setSearchParams]
  );
  const [stopStatuses, setStopStatuses] = useState<TransportStopStatus[]>([]);

  const fetchStopStatuses = useCallback(async () => {
    const statuses = await transportClient.getStopStatuses(
      agencyId,
      selectedStopId
    );
    statuses.sort((a, b) => {
      const statusOrder = ["Arrived", "Arriving", "Moving"];
      const statusComparison =
        statusOrder.indexOf(a.arrivalStatus) -
        statusOrder.indexOf(b.arrivalStatus);
      if (statusComparison !== 0) return statusComparison;
      if (a.arrivalStatus === "Moving") {
        return (
          a.remainingStops - b.remainingStops ||
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
      }
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    });

    setStopStatuses(statuses);
  }, [agencyId, selectedStopId]);

  useEffect(() => {
    fetchStopStatuses();
    const intervalId = setInterval(() => fetchStopStatuses(), 30 * 1000);
    return () => clearInterval(intervalId);
  }, [agencyId, fetchStopStatuses, selectedStopId]);

  const contextValue = useMemo(() => {
    const refreshStopStatuses = async () => {
      await transportClient.refreshStopStatuses(agencyId);
      await fetchStopStatuses();
    };

    return {
      selectedStopId,
      stopStatuses,
      setSelectedStopId: setSelectedStopIdCallback,
      refreshStopStatuses,
    } satisfies TransportStopStatusContextData;
  }, [
    agencyId,
    fetchStopStatuses,
    selectedStopId,
    setSelectedStopIdCallback,
    stopStatuses,
  ]);

  return (
    <TransportStopStatusContext.Provider value={contextValue}>
      {children}
    </TransportStopStatusContext.Provider>
  );
}

export const TransportStopStatusContextProvider = React.memo(
  TransportStopStatusContextProviderInternal
);

export function useTransportStopStatusContext() {
  const context = React.useContext(TransportStopStatusContext);
  if (!context) {
    throw new Error(
      "useTransportStopStatusContext must be used within a TransportStopStatusContextProvider"
    );
  }
  return context;
}

const useStopIdFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const stopIdSearchParamValue = searchParams.get(STOP_ID_SEARCH_PARAM);
  if (stopIdSearchParamValue == null || stopIdSearchParamValue.length === 0) {
    return null;
  }
  try {
    return parseInt(stopIdSearchParamValue, 10);
  } catch (e) {
    console.error(e);
  }
  return null;
};

export function useUpdateStopIdSearchParams() {
  const stopIdFromSearchParams = useStopIdFromSearchParams();
  const { selectedStopId, setSelectedStopId } = useTransportStopStatusContext();
  useEffect(() => {
    if (stopIdFromSearchParams !== selectedStopId) {
      setSelectedStopId(selectedStopId);
    }
  }, [selectedStopId, setSelectedStopId, stopIdFromSearchParams]);
  return setSelectedStopId;
}
