import { networkClient } from "../../client/networkClient";
import type { OnEntity } from "./OnEntity";

export type OnEntityCreationRequestPayload = Omit<OnEntity, "id">;

export type OnEntitySearchResult = Pick<OnEntity, "name" | "id">;

class EntitiesClient {
  public async getEntityById(entityId: string) {
    return networkClient.get<OnEntity>(`/entities/${entityId}`);
  }

  public async createEntity(entity: OnEntityCreationRequestPayload) {
    return networkClient.post<OnEntityCreationRequestPayload, OnEntity>(
      "/entities",
      entity
    );
  }

  public async updateEntity(
    entityId: string,
    entity: OnEntityCreationRequestPayload
  ) {
    return networkClient.put<OnEntityCreationRequestPayload, OnEntity>(
      `/entities/${entityId}`,
      entity
    );
  }

  public async deleteEntity(entityId: string) {
    return networkClient.delete(`/entities/${entityId}`);
  }

  public async searchEntities(query: string) {
    return networkClient.post<{ query: string }, OnEntitySearchResult[]>(
      `/entities/search`,
      { query }
    );
  }
}

export const entitiesClient = new EntitiesClient();
