import { v4 as uuidv4 } from "uuid";
import { networkClient } from "../client/networkClient";

const WEB_LOGGING_PREFIX = "logs";

const DEVICE_ID_LOCAL_STORAGE_KEY = "did";
const SESSION_ID_SESSION_KEY = "did";

function getRandomId(length: number = 24) {
  return uuidv4().replace(/-/g, "").substring(0, length);
}

function getDeviceId() {
  const existingDeviceId = localStorage.getItem(DEVICE_ID_LOCAL_STORAGE_KEY);
  if (existingDeviceId != null && typeof existingDeviceId === "string") {
    return existingDeviceId;
  }

  const newDeviceId = getRandomId();
  localStorage.setItem(DEVICE_ID_LOCAL_STORAGE_KEY, newDeviceId);
  return newDeviceId;
}

function getSessionId() {
  const existingSessionId = sessionStorage.getItem(SESSION_ID_SESSION_KEY);
  if (existingSessionId != null && typeof existingSessionId === "string") {
    return existingSessionId;
  }

  const newSessionId = getRandomId();
  sessionStorage.setItem(SESSION_ID_SESSION_KEY, newSessionId);
  return newSessionId;
}

// as per https://github.com/winstonjs/winston
export enum LogLevel {
  Error = "error",
  Warn = "warn",
  Info = "info",
  Http = "http",
  Verbose = "verbose",
  Debug = "debug",
  Silly = "silly",
}

type UserLogMetadata = {
  event: UserEventId;
  [key: string]: unknown;
};

type UserLogPayload = UserLogMetadata & {
  level: LogLevel;
  message: string;
  traceId: string;
  sessionId: string;
  deviceId: string;
  [key: string]: unknown;
};

class WebLoggerClient {
  public async log(
    level: LogLevel,
    message: string,
    metadata: UserLogMetadata
  ) {
    const traceId = getRandomId();
    const deviceId = getDeviceId();
    const sessionId = getSessionId();
    const { event, ...data } = metadata;
    const payload = {
      level,
      message,
      traceId,
      sessionId,
      deviceId,
      event,
      data,
    } satisfies UserLogPayload;
    return networkClient.post<UserLogPayload, void>(
      `/${WEB_LOGGING_PREFIX}`,
      payload
    );
  }
}

export enum UserEventId {
  APP_LOADED = "app_loaded",
  EVENT_CLICKED = "event_clicked",
  EVENT_PAGE_LOADED = "event_page_loaded",
  EVENT_LOAD_MORE_FUTURE = "event_load_more_future",
  EVENT_LOAD_MORE_PAST = "event_load_more_past",
  HIGHLIGHT_SHOWN = "highlight_shown",
  HIGHLIGHT_PAGE_LOADED = "highlight_page_loaded",
}

export const webLogger = new WebLoggerClient();
