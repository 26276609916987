import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useSetSearchParam() {
  const [searchParams, setSearchParams] = useSearchParams();
  return useCallback(
    (key: string, value: string) => {
      searchParams.set(key, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
}
