import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { transportClient } from "./transportClient";

export type TransportStop = {
  createdAt: Date;
  updatedAt: Date;
  agencyId: string;
  stopId: number;
  stopName: string;
  alias: string;
};

export type TransportRoute = {
  createdAt: Date;
  updatedAt: Date;
  agencyId: string;
  routeId: number;
  routeShortName: string;
  routeLongName: string;
};

export type TransportTrip = {
  createdAt: Date;
  updatedAt: Date;
  agencyId: string;
  tripId: string;
  routeId: number;
  tripHeadsign: string;
  directionId: number;
  stops: Array<TransportStop["stopId"]>;
};

interface TransportMetadataContextData {
  agencyId: string;
  stops: Map<TransportStop["stopId"], TransportStop>;
  routes: Map<TransportRoute["routeId"], TransportRoute>;
  trips: Map<TransportTrip["tripId"], TransportTrip>;
}

export const TransportMetadataContext =
  createContext<TransportMetadataContextData | null>(null);

interface TransportMetadataContextProviderProps {
  children: React.ReactNode;
}

function TransportMetadataContextProviderInternal({
  children,
}: TransportMetadataContextProviderProps) {
  const agencyId = "1";
  const [stops, setStops] = useState(
    new Map<TransportStop["stopId"], TransportStop>()
  );
  const [routes, setRoutes] = useState(
    new Map<TransportRoute["routeId"], TransportRoute>()
  );
  const [trips, setTrips] = useState(
    new Map<TransportTrip["tripId"], TransportTrip>()
  );

  const hasLoadedData = useRef(0);
  useEffect(() => {
    if (hasLoadedData.current) {
      return;
    }
    hasLoadedData.current = 1;
    const fetchRoutes = async () => {
      const routes = await transportClient.getRoutes(agencyId);
      setRoutes(new Map(routes.map(route => [route.routeId, route])));
    };
    const fetchStops = async () => {
      const stops = await transportClient.getStops(agencyId);
      stops.sort(
        (a, b) =>
          a.stopName.localeCompare(b.stopName) || a.alias.localeCompare(b.alias)
      );
      setStops(new Map(stops.map(stop => [stop.stopId, stop])));
    };
    const fetchTrips = async () => {
      const trips = await transportClient.getTrips(agencyId);
      setTrips(new Map(trips.map(trip => [trip.tripId, trip])));
    };
    fetchTrips();
    fetchStops();
    fetchRoutes();
  }, [agencyId]);

  const contextValue = useMemo(() => {
    return {
      agencyId,
      stops,
      routes,
      trips,
    } satisfies TransportMetadataContextData;
  }, [routes, stops, trips]);

  return (
    <TransportMetadataContext.Provider value={contextValue}>
      {children}
    </TransportMetadataContext.Provider>
  );
}

export const TransportMetadataContextProvider = React.memo(
  TransportMetadataContextProviderInternal
);

export function useTransportMetadataContext() {
  const context = React.useContext(TransportMetadataContext);
  if (!context) {
    throw new Error(
      "useTransportMetadataContext must be used within a TransportMetadataContextProvider"
    );
  }
  return context;
}
