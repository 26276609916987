import React from "react";
import { Text } from "./Text";

type BadgeProps = {
  color: keyof typeof pillColorToClass;
  content: string;
};

export const pillColorToClass = {
  amber: "border-amber-300 bg-amber-100/50",
  green: "border-green-400 bg-green-100/50",
  blue: "border-blue-300 bg-blue-100/50",
  red: "border-red-300 bg-red-100/50",
};

export function Badge({ content, color }: BadgeProps) {
  return (
    <div
      className={`border px-2 h-7 rounded-md flex items-center justify-center shrink-0 ${pillColorToClass[color]}`}
    >
      <Text size="small" color={color}>
        {content}
      </Text>
    </div>
  );
}
