import { networkClient } from "../../client/networkClient";

const WEATHER_PREFIX = "weather";

export type CurrentWeather = {
  createdAt: Date; //
  time: number; // unixtime
  city: string;
  latitute: number;
  longitude: number;
  temperature_2m: number;
  apparent_temperature: number; // °C
  is_day: boolean;
  precipitation: number; // mm
  relative_humidity_2m: number; // %
  rain: number; // mm
  snowfall: number; // cm
  showers: number; // mm
  cloud_cover: number; // %
  wind_speed_10m: number; // km/h
  wind_direction_10m: number; // ° degree
  weather_code: number;
};

class WeatherClient {
  public async getCurrentWeather() {
    return networkClient.get<CurrentWeather>(`/${WEATHER_PREFIX}/current`);
  }
}

export const weatherClient = new WeatherClient();
