import classNames from "classnames";
import React from "react";
import { Text } from "../common/Text";
import { useUserContext } from "../user/UserContext";

export default function Search() {
  const { user } = useUserContext();
  if (user == null) {
    return null;
  }
  return (
    <div
      className={classNames(
        "bg-slate-400",
        "row-start-2 col-span-full md:col-span-2 md:row-span-1 md:row-start-1",
        "text-center flex items-center justify-center"
      )}
    >
      {user != null ? (
        <Text emphasized>Hi, {user?.name ?? user?.username}</Text>
      ) : null}
    </div>
  );
}
