import { format } from "date-fns";
import { ro } from "date-fns/locale";
import React, { createContext, useCallback, useMemo, useState } from "react";
import type { FileWithPreview } from "../../common/ImageUpload";
import { TODAY_AT_MIDNIGHT } from "../../utils/utils";
import type { OnMedia } from "../Events/MediaView";
import {
  DayHighlightStatus,
  type CreateDayHighlightRequestPayload,
  type DayHighlight,
} from "./highlightClient";
import { useDayHighlightsContext } from "./HighlightsContext";

type HighlightEditingContextData = {
  date: string;
  setDate: (date: Date) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (description: string) => void;
  content: string;
  setContent: (content: string) => void;
  status: DayHighlightStatus;
  setStatus: (status: DayHighlightStatus) => void;

  hasDraftState: boolean;

  existingImages: OnMedia[];
  finalImagesOrder: string[]; // names
  imagesToUpload: FileWithPreview[];
  imagesToDelete: string[]; // ids
  setImages: (images: FileWithPreview[]) => void;

  saveHighlight: () => Promise<string>;
};

export const HighlightEditingContext =
  createContext<HighlightEditingContextData | null>(null);

interface HighlightEditingContextProviderProps {
  highlightToEdit?: DayHighlight | null;
  children: React.ReactNode;
}

export function HighlightEditingContextProviderInternal({
  children,
  highlightToEdit,
}: HighlightEditingContextProviderProps) {
  const { createHighlight, updateHighlight, updateMedia } =
    useDayHighlightsContext();

  const [date, setHighlightDate] = useState<string>(() => {
    return highlightToEdit?.date != null
      ? highlightToEdit?.date
      : getHighlightDateFromDate(TODAY_AT_MIDNIGHT);
  });
  const setDate = useCallback((date: Date) => {
    const newDate = getHighlightDateFromDate(date);
    console.log({ newDate });
    setHighlightDate(newDate);
  }, []);

  const [title, setTitle] = useState<string>(highlightToEdit?.title ?? "");
  const [description, setDescription] = useState<string>(
    highlightToEdit?.description ?? ""
  );
  const [content, setContent] = useState<string>(
    highlightToEdit?.content ?? ""
  );
  const [status, setStatus] = useState<DayHighlightStatus>(
    highlightToEdit?.status ?? DayHighlightStatus.Draft
  );

  const existingImages = useMemo(
    () => highlightToEdit?.media ?? [],
    [highlightToEdit?.media]
  );
  const [finalImagesOrder, setFinalImagesOrder] = useState<string[]>(
    existingImages.map(media => media.name)
  );
  const [imagesToUpload, setImagesToUpload] = useState<FileWithPreview[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);

  const setImages = useCallback(
    (images: FileWithPreview[]) => {
      const newImagesOrder = images.map(image => image.name);
      setFinalImagesOrder(newImagesOrder);
      const newImages = images.filter(
        image =>
          !existingImages.some(
            existingImage => existingImage.name === image.name
          )
      );
      setImagesToUpload(newImages);
      setImagesToDelete(
        existingImages
          .filter(existingImage => !newImagesOrder.includes(existingImage.name))
          .map(media => media.id)
      );
    },
    [existingImages]
  );

  const hasDraftState = useMemo(() => {
    if (highlightToEdit == null) {
      return (
        title.length > 0 ||
        description.length > 0 ||
        content.length > 0 ||
        date.length > 0 ||
        status !== DayHighlightStatus.Draft
      );
    } else {
      return (
        title !== highlightToEdit.title ||
        description !== highlightToEdit.description ||
        content !== highlightToEdit.content ||
        date !== highlightToEdit.date ||
        status !== highlightToEdit.status ||
        imagesToUpload.length > 0 ||
        imagesToDelete.length > 0
      );
    }
  }, [
    content,
    date,
    description,
    highlightToEdit,
    imagesToDelete.length,
    imagesToUpload.length,
    status,
    title,
  ]);

  const prepareAndUpdateMedia = useCallback(
    async (highlightId: string) => {
      let newMedia: null | FormData = null;
      if (imagesToUpload.length > 0) {
        newMedia = new FormData();
        Array.from(imagesToUpload).forEach(file => {
          newMedia!.append("files", file);
        });
      }
      await updateMedia(highlightId, {
        newMedia,
        mediaToDelete: imagesToDelete,
        mediaOrder: [],
      });
      // console.log({ event });
    },
    [imagesToDelete, imagesToUpload, updateMedia]
  );

  const saveHighlight = useCallback(async () => {
    if (date === "" || title === "" || description === "" || content === "") {
      throw new Error("All fields are required");
    }

    const payloadPayload = {
      date,
      title,
      description,
      content,
      status,
    } satisfies CreateDayHighlightRequestPayload;

    if (highlightToEdit == null) {
      const newHighlight = await createHighlight(payloadPayload);
      return newHighlight.id;
    } else {
      await updateHighlight(highlightToEdit.id, payloadPayload);
      await prepareAndUpdateMedia(highlightToEdit.id);
      return highlightToEdit.id;
    }
  }, [
    date,
    title,
    description,
    content,
    status,
    highlightToEdit,
    createHighlight,
    updateHighlight,
    prepareAndUpdateMedia,
  ]);

  const value = useMemo(() => {
    return {
      date,
      setDate,
      title,
      setTitle,
      description,
      setDescription,
      content,
      setContent,
      status,
      setStatus,

      hasDraftState,

      existingImages,
      finalImagesOrder,
      imagesToUpload,
      imagesToDelete,
      setImages,
      saveHighlight,
    } satisfies HighlightEditingContextData;
  }, [
    content,
    date,
    description,
    existingImages,
    finalImagesOrder,
    hasDraftState,
    imagesToDelete,
    imagesToUpload,
    saveHighlight,
    setDate,
    setImages,
    status,
    title,
  ]);

  return (
    <HighlightEditingContext.Provider value={value}>
      {children}
    </HighlightEditingContext.Provider>
  );
}

export const HighlightEditingContextProvider = React.memo(
  HighlightEditingContextProviderInternal
);

export function useHighlightEditingContext() {
  const context = React.useContext(HighlightEditingContext);
  if (context == null) {
    throw new Error(
      "useHighlightEditingContext must be used within a HighlightEditingContextProvider"
    );
  }
  return context;
}

export function getHighlightDateFromDate(date: Date) {
  return format(date, "yyyy-MM-dd", { locale: ro });
}
