import classNames from "classnames";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { useUserContext } from "../user/UserContext";

interface SidebarProps {
  isSidebarVisible: boolean;
  toggleSidebar: () => void;
}

export default function Sidebar({
  isSidebarVisible,
  toggleSidebar,
}: SidebarProps) {
  const navigate = useNavigate();
  const { logout } = useUserContext();
  const logoutHandler = useCallback(async () => {
    await logout();
    navigate("/");
    toggleSidebar();
  }, [logout, navigate, toggleSidebar]);
  return (
    <div
      className={classNames(
        "fixed top-0 left-0 h-full w-36",
        "transition-transform transform duration-300",
        isSidebarVisible ? "translate-x-0" : "-translate-x-full",
        "p-2  flex flex-col",
        "bg-slate-600 text-white",
        "border border-l-0 border-gray-500"
      )}
    >
      <div className="w-full flex flex-col grow items-start justify-between mt-2">
        <div className="w-full flex flex-col items-start space-y-2">
          <Button
            variant="flatDark"
            onClick={() => navigate("/login")}
            value="Login"
          />
          <Button
            onClick={() => navigate("/register")}
            variant="flatDark"
            value="Register"
          />
        </div>
        <Button
          onClick={logoutHandler}
          variant="flatDark"
          value="Logout"
          className="mb-2"
        />
      </div>
    </div>
  );
}
