import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../common/Card";
import { Input } from "../common/Input";
import { Label } from "../common/Label";
import { Text } from "../common/Text";
import { useUserContext } from "./UserContext";

export default function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, logout, user } = useUserContext();

  const handleLogin = async () => {
    try {
      await login(username, password);
      navigate("/");
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const logoutHandler = useCallback(async () => {
    await logout();
    navigate("/");
  }, [logout, navigate]);

  if (user != null) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Card className="w-[350px] bg-white">
          <CardHeader>
            <CardTitle>Utilizator autentificat</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col items-start gap-2">
              <span>
                <Text className="mr-1">Nume:</Text>
                <Text emphasized>{user.name}</Text>
              </span>
              <span>
                <Text className="mr-1">Utilizator:</Text>
                <Text emphasized>{user.username}</Text>
              </span>
            </div>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Button
              value="Inapoi"
              variant="secondary"
              onClick={() => navigate("/")}
            />
            <Button onClick={logoutHandler} value="Logout" className="mb-2" />
          </CardFooter>
        </Card>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Card className="w-[350px] bg-white">
        <CardHeader>
          <CardTitle>Autentificare</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="username">Nume de utilizator</Label>
              <Input
                id="username"
                placeholder="nume de utilizator"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <Label htmlFor="password">Parola</Label>
              <Input
                id="password"
                placeholder="parola"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button
            value="Inapoi"
            variant="secondary"
            onClick={() => navigate("/")}
          />
          <Button value="Autentificare" onClick={handleLogin} />
        </CardFooter>
      </Card>
    </div>
  );
}
