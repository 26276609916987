import { User } from "../user/UserContext";
import { networkClient } from "./networkClient";

type LoginPayload = {
  username: string;
  password: string;
};

export type RegisterProps = {
  name: string;
  email: string;
  username: string;
  password: string;
};

class OnClient {
  public async getData() {
    return await networkClient.get("/example");
  }

  public async login(payload: LoginPayload) {
    return await networkClient.post<
      LoginPayload,
      {
        message: string;
        user: User;
      }
    >("/login", payload);
  }

  public async checkIsLoggedIn() {
    return await networkClient.get<{ message: string }>("/isLogged");
  }

  public async getUserData() {
    return await networkClient.get<User>("/user");
  }

  public async logout() {
    return await networkClient.post<unknown, { message: string }>(
      "/logout",
      {}
    );
  }

  public async register(details: RegisterProps) {
    return await networkClient.post<RegisterProps, { message: string }>(
      "/register",
      details
    );
  }
}

export const onClient = new OnClient();
