import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { onClient, RegisterProps } from "../client/onClient";

export interface User {
  name: string;
  username: string;
  email: string;
  roles: string[];
}

interface UserContextData {
  user: User | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (props: RegisterProps) => Promise<void>;
}

export const UserContext = createContext<UserContextData | null>(null);

interface UserProviderProps {
  children: React.ReactNode;
}

function UserContextProviderInternal({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const login = useCallback(async (username: string, password: string) => {
    try {
      const response = await onClient.login({ username, password });
      setUser(response.user);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Login failed", error);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await onClient.logout();
      setUser(null);
      setIsLoggedIn(false);
    } catch (error) {
      console.error("Logout failed", error);
    }
  }, []);

  const getUserData = useCallback(async () => {
    try {
      const user = await onClient.getUserData();
      setUser(user);
      setIsLoggedIn(true);
    } catch (error) {
      setUser(null);
      setIsLoggedIn(false);
      console.error("Login check failed", error);
    }
  }, []);

  const register = useCallback(
    async (props: RegisterProps) => {
      await onClient.register(props);
      await getUserData();
    },
    [getUserData]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const interval = setInterval(
      async () => {
        try {
          await onClient.checkIsLoggedIn();
          setIsLoggedIn(true);
        } catch (error) {
          setUser(null);
          setIsLoggedIn(false);
          console.error("Login check failed", error);
        }
      },
      1000 * 60 * 10
    );

    return () => {
      clearInterval(interval);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const value = useMemo(
    () =>
      ({
        user,
        login,
        logout,
        register,
      }) satisfies UserContextData,
    [login, logout, register, user]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const UserContextProvider = React.memo(UserContextProviderInternal);

export function useUserContext(): UserContextData {
  const userContext = useContext(UserContext);
  if (userContext == null) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return userContext;
}

export function useIsUserAdmin(): boolean {
  const { user } = useUserContext();
  return user?.roles.includes("admin") ?? false;
}

export function useIsUserEditor(): boolean {
  const { user } = useUserContext();
  return user?.roles.includes("editor") ?? false;
}
