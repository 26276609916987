import classNames from "classnames";
import { format } from "date-fns/format";
import { ro } from "date-fns/locale";
import { parse } from "date-fns/parse";
import {
  CalendarFoldIcon,
  ImageIcon,
  MapPinIcon,
  StoreIcon,
  UserCircleIcon,
} from "lucide-react";
import React, { useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "../common/Button";
import { OnStatusBadge, type OnStatus } from "../common/StatusBadge";
import { Text } from "../common/Text";
import { LogLevel, UserEventId, webLogger } from "../logging/webLogger";
import { useIsUserAdmin } from "../user/UserContext";
import { camelCase } from "../utils/camelCase";
import { plural } from "../utils/plural";
import { useEventsContext } from "./Events/EventsContext";
import type { OnEvent } from "./Events/OnEvent";
import { WidgetHeader } from "./WidgetHeader";

export function Events() {
  const isUserAdmin = useIsUserAdmin();
  const {
    events,
    moreEventsInTheFuture,
    moreEventsInThePast,
    loadFutureEvents,
    loadPastEvents,
  } = useEventsContext();

  const listRef = useRef<HTMLDivElement>(null);
  const hasSetScrollOffset = useRef(false);
  useEffect(() => {
    if (
      listRef.current != null &&
      moreEventsInThePast &&
      !hasSetScrollOffset.current
    ) {
      hasSetScrollOffset.current = true;
      setTimeout(() => {
        listRef.current!.scrollTop = 42;
      }, 10);
    }
  }, [moreEventsInThePast]);

  return (
    <div className="flex flex-col col-span-full md:col-span-8 row-span-6 border border-gray-200 hover:border-orange-600/30 rounded-md bg-white group/header">
      <div
        className={classNames(
          "flex flex-row justify-between items-center rounded-t-md",
          "shrink-0 p-2 pb-11 border-b border-gray-200 group-hover/header:bg-orange-50/70 group-hover/header:border-orange-600/30 transition-colors"
        )}
      >
        <WidgetHeader
          color="text-orange-950"
          icon={<CalendarFoldIcon className="w-4 h-4 text-orange-950" />}
        >
          Evenimente
        </WidgetHeader>
        {isUserAdmin ? (
          <Link to="/iasi/evenimente/adauga">
            <Button
              value="Adauga Eveniment"
              size="small"
              variant="outlineLight"
            />
          </Link>
        ) : null}
      </div>
      <div
        className="flex flex-grow flex-col overflow-x-clip overflow-y-scroll bg-slate-100 rounded-b-md gap-2"
        ref={listRef}
      >
        {moreEventsInThePast ? (
          <div className="w-full flex justify-center mt-2">
            <Button
              value="Vezi evenimente trecute"
              size="small"
              variant="outlineLight"
              onClick={() => {
                webLogger.log(LogLevel.Info, "Loading past events", {
                  event: UserEventId.EVENT_LOAD_MORE_PAST,
                  eventsLoaded: events.length,
                });
                loadPastEvents();
              }}
            />
          </div>
        ) : null}
        <EventsList onEvents={events} />
        {moreEventsInTheFuture ? (
          <div className="w-full flex justify-center mb-2">
            <Button
              value="Vezi mai multe"
              size="small"
              variant="outlineLight"
              onClick={() => {
                webLogger.log(LogLevel.Info, "Loading future events", {
                  event: UserEventId.EVENT_LOAD_MORE_FUTURE,
                  eventsLoaded: events.length,
                });
                loadFutureEvents();
              }}
            />
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

type EventsListProps = {
  onEvents: OnEvent[];
};

export function EventsList({ onEvents }: EventsListProps) {
  const eventsByDay = useMemo(
    () => getEventsGroupedByDay(onEvents),
    [onEvents]
  );
  return eventsByDay.map(([day, onEvents]) => {
    return (
      <>
        <div className="mx-2 mt-2 flex flex-row gap-1" key={day}>
          <Text emphasized size="small" color="secondary">
            {camelCase(
              format(parse(day, "P", new Date()), "PPPP", {
                locale: ro,
              })
            )}
          </Text>
          <Text color="secondary" size="small">
            {`- ${onEvents.length} ${plural(onEvents.length, "eveniment", "evenimente")}`}
          </Text>
        </div>
        {onEvents.map(onEvent => (
          <EventRow onEvent={onEvent} key={onEvent.id} />
        ))}
      </>
    );
  });
}

type EventRowProps = {
  onEvent: OnEvent;
};

function EventRow({ onEvent }: EventRowProps) {
  const isUserAdmin = useIsUserAdmin();
  const previewImage = onEvent.media?.[0];
  const organizerIcon =
    onEvent?.organizerType === "Entity" ? (
      <StoreIcon className="w-4 h-4 text-secondary" />
    ) : (
      <UserCircleIcon className="w-4 h-4 text-secondary" />
    );
  return (
    <Link to={`/iasi/evenimente/${onEvent.id}`} className="last:mb-2">
      <div
        className={classNames(
          "flex flex-row min-h-24 max-h-24 overflow-hidden py-2 pr-2 cursor-pointer bg-white  border border-gray-200/70 hover:border-orange-100 hover:shadow-orange-100 hover:shadow-md hover:z-50 rounded-md transition-all group/item",
          "mx-1 md:mx-2"
        )}
      >
        <div className="h-auto min-w-28 max-w-28 md:min-w-44 md:max-w-44 shrink-0 flex items-center justify-center">
          {previewImage != null ? (
            <img
              className={classNames("w-full h-full")}
              src={previewImage.url}
              alt={previewImage.name}
              style={{ objectFit: "contain" }}
            />
          ) : (
            <ImageIcon className="w-48 h-24 text-secondary/20" />
          )}
        </div>
        <div className="flex flex-col flex-grow justify-between mx-2">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-between">
              <Text
                size="base"
                className="line-clamp-1"
                emphasized
                display={null}
              >
                {onEvent.name}
              </Text>
              {isUserAdmin ? (
                <OnStatusBadge status={onEvent.status as unknown as OnStatus} />
              ) : null}
            </div>
            <Text
              color="secondary"
              size="small"
              className="line-clamp-1"
              display={null}
            >
              {onEvent.oneLine}
            </Text>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-1 items-center">
              <MapPinIcon className="w-4 h-4 text-secondary" />
              <Text
                color="secondary"
                size="small"
                className="max-w-52 whitespace-pre text-ellipsis line-clamp-1"
              >
                {onEvent.address}
              </Text>
            </div>
            <div className="flex flex-row gap-1 items-center">
              <Text
                color="secondary"
                size="small"
                className="max-w-52 whitespace-pre text-ellipsis line-clamp-1"
              >
                {onEvent.organizer?.name ?? "<organizator>"}
              </Text>
              {organizerIcon}
            </div>
          </div>
        </div>
        <div className="border rounded-md border-gray-100 shrink-0" />
        <div className="min-w-16 w-16 flex flex-col gap-1 items-center justify-center shrink-0">
          <Text
            color="secondary"
            className="group-hover/item:text-orange-600/60 transition-colors"
          >
            {onEvent.startDate != null
              ? format(onEvent.startDate, "HH:mm")
              : "25:01"}
          </Text>
          <Text
            color="secondary"
            className="group-hover/item:text-orange-600/60 transition-colors"
            size="tiny"
          >
            {format(onEvent.startDate ?? new Date(), "dd/MM/yy")}
          </Text>
        </div>
      </div>
    </Link>
  );
}

function getEventsGroupedByDay(onEvents: OnEvent[]) {
  // this is probably not needed since sorting happens on server side
  const sortedEvents = Array.from(onEvents).sort((a, b) => {
    const dateA = new Date(a.startDate ?? new Date());
    const dateB = new Date(b.startDate ?? new Date());
    return dateA.getTime() - dateB.getTime();
  });

  const eventsByDay = sortedEvents.reduce(
    (acc, onEvent) => {
      const day = format(onEvent.startDate ?? new Date(), "P");
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(onEvent);
      return acc;
    },
    {} as Record<string, OnEvent[]>
  );

  return Object.entries(eventsByDay).sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateA.getTime() - dateB.getTime();
  });
}
