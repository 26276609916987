import classNames from "classnames";
import {
  CloudDrizzleIcon,
  CloudFogIcon,
  CloudHailIcon,
  CloudIcon,
  CloudMoonIcon,
  CloudRainIcon,
  CloudRainWindIcon,
  CloudSnowIcon,
  CloudSunIcon,
  CloudyIcon,
  MoonIcon,
  SnowflakeIcon,
  SunIcon,
} from "lucide-react";
import React, { useEffect } from "react";
import { Text } from "../../common/Text";
import { useUserContext } from "../../user/UserContext";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import { CurrentWeather, weatherClient } from "./weatherClient";

export default function Weather() {
  const [currentWeather, setCurrentWeather] = React.useState<CurrentWeather>();
  useEffect(() => {
    async function fetchData() {
      const response = await weatherClient.getCurrentWeather();
      setCurrentWeather(response);
    }
    fetchData();
    const intervalId = setInterval(fetchData, 10 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);
  const temperature = currentWeather?.temperature_2m ?? "--";
  const feelsLike = currentWeather?.apparent_temperature ?? "--";
  const weatherCode = currentWeather?.weather_code ?? 0;
  const detailsObject = weatherCodeToDescription.get(weatherCode) ?? {
    description: "",
    icon: <CloudyIcon />,
  };

  const description = detailsObject.description;
  const icon =
    !currentWeather?.is_day && detailsObject.nightIcon != null
      ? detailsObject.nightIcon
      : detailsObject.icon;

  const { user } = useUserContext();
  const hasUser = user != null;
  const windowSize = useWindowSize();
  const isMobile = windowSize === WindowSize.Small;
  return (
    <div
      className={classNames(
        "col-span-6 row-span-1 row-start-1",
        hasUser && "md:col-span-5 md:row-span-1 md:row-start-1",
        !hasUser && "md:col-span-6 md:row-span-1 md:row-start-1",
        "transition-colors duration-300 pr-5 my-2"
      )}
      onClick={() => {
        if (currentWeather == null) {
          return;
        }
        const updatedAt = new Date(currentWeather?.time * 1000);
        // eslint-disable-next-line no-console
        console.log(updatedAt.toLocaleString());
      }}
    >
      <div className="flex flex-row items-center justify-end h-full">
        <div className="flex flex-col items-end">
          <div className="flex flex-row items-center gap-2">
            {icon}
            <p className="text-2xl font-semibold text-gray-200">
              {temperature}°C
            </p>
          </div>
          {isMobile ? (
            <div className="flex flex-col items-end">
              <Text color="secondaryDark" size="tiny">
                se simte: {feelsLike}°C
              </Text>
              <Text color="secondaryDark" size="tiny">
                {description}
              </Text>
            </div>
          ) : (
            <div className="flex flex-row gap-1">
              <Text color="secondaryDark" size="small">
                {description}
              </Text>
              <div className="border rounded-md border-gray-500 shrink-0" />
              <Text color="secondaryDark" size="small">
                se simte: {feelsLike}°C
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function getWeatherIcon(
  IconComponent: React.ElementType,
  NightIconComponent: React.ElementType | null = null
): { icon: JSX.Element; nightIcon?: JSX.Element | null } {
  return {
    icon: <IconComponent className="text-gray-200" strokeWidth={3} />,
    nightIcon:
      NightIconComponent != null ? (
        <NightIconComponent className="text-gray-200" strokeWidth={3} />
      ) : null,
  };
}

type WeatherDescription = {
  description: string;
  icon: JSX.Element;
  nightIcon?: JSX.Element | null;
};

const weatherCodeToDescription = new Map<number, WeatherDescription>([
  [
    0,
    {
      description: "Cer senin",
      ...getWeatherIcon(SunIcon, MoonIcon),
    },
  ],
  [
    1,
    {
      description: "Mai mult senin",
      ...getWeatherIcon(CloudSunIcon, CloudMoonIcon),
    },
  ],
  [
    2,
    {
      description: "Parțial noros",
      ...getWeatherIcon(CloudyIcon),
    },
  ],
  [
    3,
    {
      description: "Înnorat",
      ...getWeatherIcon(CloudIcon),
    },
  ],
  [
    45,
    {
      description: "Ceață",
      ...getWeatherIcon(CloudFogIcon),
    },
  ],
  [
    48,
    {
      description: "Ceață cu chiciură",
      ...getWeatherIcon(CloudFogIcon),
    },
  ],
  [
    51,
    {
      description: "Burniță: Intensitate ușoară",
      ...getWeatherIcon(CloudDrizzleIcon),
    },
  ],
  [
    53,
    {
      description: "Burniță: Intensitate moderată",
      ...getWeatherIcon(CloudDrizzleIcon),
    },
  ],
  [
    55,
    {
      description: "Burniță: Intensitate densă",
      ...getWeatherIcon(CloudDrizzleIcon),
    },
  ],
  [
    56,
    {
      description: "Burniță înghețată: Intensitate ușoară",
      ...getWeatherIcon(CloudHailIcon),
    },
  ],
  [
    57,
    {
      description: "Burniță înghețată: Intensitate densă",
      ...getWeatherIcon(CloudHailIcon),
    },
  ],
  [
    61,
    {
      description: "Ploaie: Intensitate ușoară",
      ...getWeatherIcon(CloudRainIcon),
    },
  ],
  [
    63,
    {
      description: "Ploaie: Intensitate moderată",
      ...getWeatherIcon(CloudRainIcon),
    },
  ],
  [
    65,
    {
      description: "Ploaie: Intensitate puternică",
      ...getWeatherIcon(CloudRainIcon),
    },
  ],
  [
    66,
    {
      description: "Ploaie înghețată: Intensitate ușoară",
      ...getWeatherIcon(CloudHailIcon),
    },
  ],
  [
    67,
    {
      description: "Ploaie înghețată: Intensitate puternică",
      ...getWeatherIcon(CloudHailIcon),
    },
  ],
  [
    71,
    {
      description: "Ninsoare: Intensitate ușoară",
      ...getWeatherIcon(CloudSnowIcon),
    },
  ],
  [
    73,
    {
      description: "Ninsoare: Intensitate moderată",
      ...getWeatherIcon(CloudSnowIcon),
    },
  ],
  [
    75,
    {
      description: "Ninsoare: Intensitate puternică",
      ...getWeatherIcon(CloudSnowIcon),
    },
  ],
  [
    77,
    {
      description: "Fulgi de zăpadă",
      ...getWeatherIcon(SnowflakeIcon),
    },
  ],
  [
    80,
    {
      description: "Averse de ploaie: Intensitate ușoară",
      ...getWeatherIcon(CloudRainWindIcon),
    },
  ],
  [
    81,
    {
      description: "Averse de ploaie: Intensitate moderată",
      ...getWeatherIcon(CloudRainWindIcon),
    },
  ],
  [
    82,
    {
      description: "Averse de ploaie: Intensitate violentă",
      ...getWeatherIcon(CloudRainWindIcon),
    },
  ],
  [
    85,
    {
      description: "Averse de ninsoare: Intensitate ușoară",
      ...getWeatherIcon(CloudSnowIcon),
    },
  ],
  [
    86,
    {
      description: "Averse de ninsoare: Intensitate puternică",
      ...getWeatherIcon(CloudSnowIcon),
    },
  ],
  [
    95,
    {
      description: "Furtună",
      ...getWeatherIcon(CloudRainWindIcon),
    },
  ],
  [
    96,
    {
      description: "Furtună cu grindină ușoară",
      ...getWeatherIcon(CloudRainWindIcon),
    },
  ],
  [
    99,
    {
      description: "Furtună cu grindină puternică",
      ...getWeatherIcon(CloudRainWindIcon),
    },
  ],
]);
