import React from "react";
import { joinClasses } from "../utils/joinClasses";

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  size?: "default" | "icon" | "small";
  icon?: React.ReactNode;
  value: string;
  rightIcon?: React.ReactNode;
}

export const OnLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, icon, value, rightIcon, ...props }, ref) => {
    const computedClassName = joinClasses(
      "inline-flex items-center justify-center whitespace-nowrap  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 gap-1 text-primary underline-offset-4 hover:underline shrink-0",
      className
    );

    return (
      <a className={computedClassName} ref={ref} {...props}>
        {icon} {value} {rightIcon}
      </a>
    );
  }
);
OnLink.displayName = "Link";
