import { useEffect, useState } from "react";

export enum WindowSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWindowSize(): WindowSize {
    const width = window.innerWidth;
    if (width < 640) return WindowSize.Small;
    if (width < 1024) return WindowSize.Medium;
    return WindowSize.Large;
  }

  return windowSize;
}
