import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  highlightsClient,
  type CreateDayHighlightRequestPayload,
  type DayHighlight,
} from "./highlightClient";

interface HighlightsContextData {
  highlights: Map<string, DayHighlight>;
  loadedDays: Set<string>;
  loadingState: "initial" | "loading" | "ready";
  loadHighlights: (date: string) => Promise<void>;
  getHighlight: (highlightId: string) => Promise<void>;
  createHighlight: (
    highlight: CreateDayHighlightRequestPayload
  ) => Promise<DayHighlight>;
  updateHighlight: (
    highlightId: string,
    highlight: CreateDayHighlightRequestPayload
  ) => Promise<DayHighlight>;
  deleteHighlight: (highlightId: string) => Promise<void>;
  updateMedia: (
    highlightId: string,
    options: {
      newMedia: FormData | null;
      mediaToDelete: string[]; // ids
      mediaOrder: string[]; // names
    }
  ) => Promise<void>;
}

export const HighlightsContext = createContext<HighlightsContextData | null>(
  null
);

interface HighlightsContextProviderProps {
  children: React.ReactNode;
}

function HighlightsContextProviderInternal({
  children,
}: HighlightsContextProviderProps) {
  // state
  const [highlights, setHighlights] = useState<Map<string, DayHighlight>>(
    new Map()
  );
  const [loadedDays, setLoadedDays] = useState<Set<string>>(new Set());
  const [loadingState, setLoadingState] = useState<
    "initial" | "loading" | "ready"
  >("initial");

  const updateStateHighlights = useCallback(
    (newHighlights: DayHighlight[]) => {
      const existingHighlights = new Map(highlights);
      newHighlights.forEach(highlight => {
        existingHighlights.set(highlight.id, highlight);
      });
      setHighlights(existingHighlights);
    },
    [highlights]
  );

  // actions
  const loadHighlights = useCallback(
    async (date: string) => {
      setLoadingState("loading");
      try {
        const highlights = await highlightsClient.getHighlights(date);
        updateStateHighlights(highlights);
        const newDays = new Set(loadedDays);
        newDays.add(date);
        setLoadedDays(newDays);
      } catch (error) {
        console.error("Load highlights failed", error);
      }
      setLoadingState("ready");
    },
    [loadedDays, updateStateHighlights]
  );

  const getHighlight = useCallback(
    async (highlightId: string) => {
      setLoadingState("loading");
      let highlight = null;
      try {
        highlight = await highlightsClient.getHighlight(highlightId);
      } catch (error) {
        console.error("Get highlight failed", error);
      }
      if (highlight != null) {
        updateStateHighlights([highlight]);
      }
      setLoadingState("ready");
    },
    [updateStateHighlights]
  );

  const createHighlight = useCallback(
    async (highlight: CreateDayHighlightRequestPayload) => {
      const newHighlight = await highlightsClient.createHighlight(highlight);
      updateStateHighlights([newHighlight]);
      return newHighlight;
    },
    [updateStateHighlights]
  );

  const updateHighlight = useCallback(
    async (
      highlightId: string,
      updatedHighlight: CreateDayHighlightRequestPayload
    ) => {
      const highlight = await highlightsClient.updateHighlight(
        highlightId,
        updatedHighlight
      );
      updateStateHighlights([highlight]);
      return highlight;
    },
    [updateStateHighlights]
  );

  const deleteHighlight = useCallback(
    async (highlightId: string) => {
      try {
        await highlightsClient.deleteHighlight(highlightId);
        const newHighlights = new Map(highlights);
        newHighlights.delete(highlightId);
        setHighlights(newHighlights);
      } catch (error) {
        console.error("Delete highlight failed", error);
      }
    },
    [highlights]
  );

  const updateMedia = useCallback(
    async (
      highlightId: string,
      options: {
        newMedia: FormData | null;
        mediaToDelete: string[]; // ids
        mediaOrder: string[]; // names
      }
    ) => {
      const { newMedia, mediaToDelete, mediaOrder } = options;
      let highlight = null;
      if (newMedia != null) {
        highlight = await highlightsClient.uploadMedia(highlightId, newMedia);
      }
      if (mediaToDelete.length > 0) {
        highlight = await highlightsClient.deleteMedia(
          highlightId,
          mediaToDelete
        );
      }
      if (highlight != null) {
        updateStateHighlights([highlight]);
      }
    },
    [updateStateHighlights]
  );

  const value = useMemo(() => {
    return {
      highlights,
      loadedDays,
      loadingState,
      loadHighlights,
      getHighlight,
      createHighlight,
      updateHighlight,
      deleteHighlight,
      updateMedia,
    } satisfies HighlightsContextData;
  }, [
    createHighlight,
    deleteHighlight,
    getHighlight,
    highlights,
    loadHighlights,
    loadedDays,
    loadingState,
    updateHighlight,
    updateMedia,
  ]);

  return (
    <HighlightsContext.Provider value={value}>
      {children}
    </HighlightsContext.Provider>
  );
}

export const HighlightsContextProvider = React.memo(
  HighlightsContextProviderInternal
);

export function useDayHighlightsContext() {
  const context = React.useContext(HighlightsContext);
  if (context === null) {
    throw new Error(
      "useDayHighlights must be used within a HighlightsContextProvider"
    );
  }
  return context;
}

export function useDayHighlights(date: string): DayHighlight[] {
  const { highlights, loadedDays, loadHighlights } = useDayHighlightsContext();
  const hasDayLoaded = loadedDays.has(date);
  const initiatedLoading = useRef(hasDayLoaded);
  useEffect(() => {
    if (!initiatedLoading.current) {
      initiatedLoading.current = true;
      loadHighlights(date);
    }
  }, [date, loadHighlights]);

  const dayHighlights = useMemo(() => {
    return Array.from(highlights.values())
      .filter(highlight => highlight.date === date)
      .sort((a, b) => a.order - b.order);
  }, [date, highlights]);

  return dayHighlights;
}

export function useHighlight(
  highlightId: string | null | undefined
): DayHighlight | null {
  const { highlights, getHighlight } = useDayHighlightsContext();
  const hasHighlight = highlights.has(highlightId ?? "");
  const initiatedLoading = useRef(hasHighlight);

  useEffect(() => {
    if (highlightId == null) {
      return;
    }

    if (!initiatedLoading.current) {
      initiatedLoading.current = true;
      getHighlight(highlightId);
    }
  }, [getHighlight, hasHighlight, highlightId]);

  if (highlightId == null) {
    return null;
  }
  return highlights.get(highlightId) ?? null;
}
