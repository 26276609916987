import axios, { AxiosRequestConfig } from "axios";

// import { getCredentials } from "./localUtils";

const server: string = "/api";

export class NetworkClient {
  public async post<B, R>(
    endpoint: string,
    data: B,
    customOptions?: AxiosRequestConfig
  ): Promise<R> {
    let axiosResponse;

    const options = this.getOptions(customOptions);
    try {
      axiosResponse = await axios.post<R>(server + endpoint, data, options);
    } catch (error) {
      console.error("Error on request", server + endpoint, data, options);
      throw error;
    }

    if (!axiosResponse) {
      console.error(
        "No response from server",
        server + endpoint,
        data,
        options
      );
      throw Error("No response from server");
    }

    return axiosResponse.data;
  }

  public async put<B, R>(
    endpoint: string,
    data?: B,
    customOptions?: AxiosRequestConfig
  ): Promise<R> {
    let axiosResponse;

    const options = this.getOptions(customOptions);
    try {
      axiosResponse = await axios.put<R>(server + endpoint, data, options);
    } catch (error) {
      console.error("Error on request put", server + endpoint, data, options);
      throw error;
    }

    if (!axiosResponse) {
      console.error(
        "No response from server",
        server + endpoint,
        data,
        options
      );
      throw Error("No response from server");
    }

    return axiosResponse.data;
  }

  public async get<R>(
    endpoint: string,
    customOptions?: Partial<AxiosRequestConfig>
  ) {
    let axiosResponse;
    const options = this.getOptions(customOptions);
    try {
      axiosResponse = await axios.get<R>(server + endpoint, options);
    } catch (error) {
      console.error("Error on request get", server + endpoint, options);
      throw error;
    }

    if (!axiosResponse) {
      console.error("No response from server", server + endpoint, options);
      throw Error("No response from server");
    }
    return axiosResponse.data;
  }

  public async delete<R>(
    endpoint: string,
    customOptions?: Partial<AxiosRequestConfig>
  ) {
    const options = this.getOptions(customOptions);
    let axiosResponse;
    try {
      axiosResponse = await axios.delete<R>(server + endpoint, options);
    } catch (error) {
      console.error("Error on request delete", server + endpoint, options);
      throw error;
    }
    return axiosResponse.data;
  }

  private getOptions(customOptions?: AxiosRequestConfig) {
    const finalOptions: AxiosRequestConfig = { ...customOptions };
    // const creds = getCredentials();
    // const definescToken = creds?.token;
    if (finalOptions.headers == null) {
      finalOptions.headers = {};
    }
    // finalOptions.headers.Authorization = `Bearer ${definescToken}`;
    return finalOptions;
  }
}

export const networkClient = new NetworkClient();
