import { PlusIcon, TrashIcon } from "lucide-react";
import React, {
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
  type DragEvent,
} from "react";
import type { OnMedia } from "../widgets/Events/MediaView";
import { Badge } from "./Badge";
import { Button } from "./Button";
import { Text } from "./Text";

export interface FileWithPreview extends File {
  url: string;
}

type ImageUploadProps = {
  setImages: (images: Array<FileWithPreview>) => void;
  existingImages: OnMedia[];
  finalImagesOrder: string[];
  imagesToUpload: FileWithPreview[];
  imagesToDelete: string[];
};

export function ImageUpload({
  setImages,
  existingImages,
  finalImagesOrder,
  imagesToUpload,
  imagesToDelete,
}: ImageUploadProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault(); // Necessary to allow a drop
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const processAndAddImages = (addedImages: FileList | null): void => {
    setError(undefined);
    const remainingSlots = 5 - images.length;
    const existingImagesNames = new Set(images.map(file => file.name));
    const validImages = Array.from(addedImages ?? [])
      .filter(file => {
        if (file == null) {
          return false;
        }

        if (existingImagesNames.has(file.name)) {
          return false;
        }

        if (
          !file.type.startsWith("image/") ||
          !(
            file.type.endsWith("jpg") ||
            file.type.endsWith("jpeg") ||
            file.type.endsWith("png")
          )
        ) {
          setError("Only jpeg and png files arde allowed");
          return false;
        }

        // Validate file size (5MB limit)
        if (file.size > 5 * 1024 * 1024) {
          setError("Image size should be less than 5MB");
          return false;
        }

        return true;
      })
      .map(file =>
        Object.assign(file, {
          url: URL.createObjectURL(file),
        })
      )
      .slice(0, remainingSlots);
    setImages([...images, ...validImages]);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(false);

    processAndAddImages(e.dataTransfer.files);
  };

  const handleImagesChange = (e: ChangeEvent<HTMLInputElement>): void => {
    processAndAddImages(e.target.files);
  };

  const handleDivClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    if (e.target instanceof HTMLSpanElement) {
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const images = useMemo(() => {
    return finalImagesOrder
      .map(imageName => {
        const image = existingImages.find(image => image.name === imageName);
        if (image) {
          return image;
        }
        return imagesToUpload.find(image => image.name === imageName);
      })
      .filter(Boolean) as FileWithPreview[];
  }, [existingImages, finalImagesOrder, imagesToUpload]);

  return (
    <div>
      <ImageList
        images={images}
        existingImages={existingImages}
        imagesToUpload={imagesToUpload}
        imagesToDelete={imagesToDelete}
        onDelete={index => {
          const updatedImages = [...images];
          updatedImages.splice(index, 1);
          setImages(updatedImages);
        }}
      />
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleDivClick}
        className={`mt-2 flex flex-row items-center rounded-md border border-dashed border-gray-300 hover:border-blue-300 p-2 gap-2 cursor-pointer ${isDragging ? "bg-blue-100" : "bg-white"}`}
      >
        <PlusIcon className="h-12 w-12 text-gray-400" />
        <div>
          <label
            htmlFor="image-upload"
            className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500"
          >
            <span>Selecteaza imagini</span>
            <input
              type="file"
              multiple
              onChange={handleImagesChange}
              style={{ display: "none" }}
              id="image-upload"
              ref={fileInputRef}
            />
          </label>
          <p>sau trage aici</p>
          <p className="text-xs text-gray-500">
            PNG, JPG, GIF pana la 5MB, maxim 5 imagini
          </p>
        </div>
      </div>

      {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
    </div>
  );
}

type ImageListProps = {
  images: Array<FileWithPreview>;
  onDelete: (index: number) => void;
  existingImages: ImageUploadProps["existingImages"];
  imagesToUpload: ImageUploadProps["imagesToUpload"];
  imagesToDelete: ImageUploadProps["imagesToDelete"];
};

function ImageList({
  // existingImages,
  images,
  // imagesToDelete,
  imagesToUpload,
  onDelete,
}: ImageListProps) {
  return (
    <div className="flex flex-col gap-2">
      {images.map((images, index) => {
        const isNew = imagesToUpload.includes(images);
        const isHeader = index === 0;
        return (
          <div
            key={images.name}
            className="border border-slate-100 rounded-md bg-slate-50 p-2 flex flex-row gap-2"
          >
            <img
              className="rounded-md min-h-24 h-24 w-48 min-w-48 border border-slate-200"
              src={images.url}
              alt={images.name}
              style={{ objectFit: "contain" }}
            />
            <div className="flex flex-col items-start grow shrink justify-between">
              <Text
                className="line-clamp-1 text-wrap"
                style={{ overflowWrap: "anywhere" }}
                display={null}
              >
                {images.name}
              </Text>
              <div className="flex flex-row gap-2">
                {isHeader ? <Badge content="Principal" color="green" /> : null}
                {isNew ? <Badge content="Nou" color="blue" /> : null}
              </div>
            </div>
            <div className="shrink-0">
              <Button
                value="Elimina"
                variant="outlineLight"
                icon={<TrashIcon className="h-4 w-4" />}
                onClick={() => onDelete(index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
