"use client";

import { CheckIcon, SearchIcon } from "lucide-react";

import classNames from "classnames";
import React, { useState } from "react";
import { joinClasses } from "../utils/joinClasses";
import { Input } from "./Input";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { Text } from "./Text";

export type SearchBoxItemProps<idT> = {
  id: idT;
  displayName: string;
};

type SearchBoxProps<idT> = {
  results: Array<SearchBoxItemProps<idT>>;
  selectedValue: SearchBoxItemProps<idT> | undefined;
  onValueChange: (newSearchValue: string) => void;
  onValueSelection: (id: idT) => void;
  emptyResultsMessage?: string;
  searchBoxPlaceholder?: string;
  inputIcon?: React.ReactNode;
};

export function SearchBox<idT extends string | number>({
  results,
  selectedValue,
  onValueChange,
  onValueSelection,
  emptyResultsMessage = "Nu s-au gasit rezultate.",
  searchBoxPlaceholder = "Cauta...",
  inputIcon,
}: SearchBoxProps<idT>) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div
          className={
            "bg-white flex flex-row justify-start gap-1 items-center py-1 px-2 h-9 grow basis-0 min-w-0 hover:bg-gray-100 hover:shadow border hover:border-gray-300 cursor-pointer whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
          }
        >
          {inputIcon ?? (
            <SearchIcon className="h-4 w-4 shrink-0 text-secondary" />
          )}
          {selectedValue ? (
            <Text size="base" className="font-normal">
              {selectedValue.displayName}
            </Text>
          ) : (
            <Text color="secondary" size="base" className="font-normal">
              {searchBoxPlaceholder}
            </Text>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="min-w-[240px] p-0">
        <div className="flex flex-row grow items-center gap-2">
          <SearchIcon className="h-4 w-4 shrink-0 opacity-50" />
          <Input
            className="grow"
            hasBorder={false}
            placeholder={searchBoxPlaceholder}
            onChange={e => {
              setInputValue(e.target.value);
              onValueChange(e.target.value);
            }}
          />
        </div>
        {results.length === 0 && inputValue.length > 0 ? (
          <Text color="secondary" className="mt-2 py-1.5 px-2">
            {emptyResultsMessage}
          </Text>
        ) : (
          <div
            className={classNames(
              "flex flex-col max-h-[240px] overflow-y-auto",
              results.length > 0 ? "mt-2" : ""
            )}
          >
            {results.map(result => {
              const isSelected = result.id === selectedValue?.id;
              return (
                <div
                  className={joinClasses(
                    isSelected
                      ? "bg-blue-500 text-white hover:bg-blue-300 hover:text-black"
                      : "hover:bg-blue-100",
                    "flex flex-row justify-between items-center  cursor-pointer rounded-md py-1.5 px-2"
                  )}
                  key={result.id}
                  onClick={() => {
                    onValueSelection(result.id);
                    setOpen(false);
                  }}
                >
                  {result.displayName}
                  {isSelected ? <CheckIcon className="h-4 w-4" /> : null}
                </div>
              );
            })}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
