import { networkClient } from "../../client/networkClient";
import type {
  TransportRoute,
  TransportStop,
  TransportTrip,
} from "./TransportMetadataContext";
import type { TransportStopStatus } from "./TransportStopStatusContext";

const TRANSPORT_PREFIX = "transport";

type TransportRouteAxios = TransportRoute & {
  createdAt: string;
  updatedAt: string;
};

type TransportStopAxios = TransportStop & {
  createdAt: string;
  updatedAt: string;
};

type TransportTripAxios = TransportTrip & {
  createdAt: string;
  updatedAt: string;
};

type TransportStopStatusAxios = TransportStopStatus & {
  createdAt: string;
  updatedAt: string;
};

class TransportClient {
  public async getRoutes(agencyId: string): Promise<TransportRoute[]> {
    const routes = await networkClient.get<TransportRouteAxios[]>(
      `/${TRANSPORT_PREFIX}/agencies/${agencyId}/routes`
    );
    return routes.map(
      route =>
        ({
          ...route,
          createdAt: new Date(route.createdAt),
          updatedAt: new Date(route.updatedAt),
        }) satisfies TransportRoute
    );
  }

  public async getStops(agencyId: string): Promise<TransportStop[]> {
    const stops = await networkClient.get<TransportStopAxios[]>(
      `/${TRANSPORT_PREFIX}/agencies/${agencyId}/stops`
    );
    return stops.map(
      stop =>
        ({
          ...stop,
          createdAt: new Date(stop.createdAt),
          updatedAt: new Date(stop.updatedAt),
        }) satisfies TransportStop
    );
  }

  public async getTrips(agencyId: string): Promise<TransportTrip[]> {
    const trips = await networkClient.get<TransportTripAxios[]>(
      `/${TRANSPORT_PREFIX}/agencies/${agencyId}/trips`
    );
    return trips.map(
      trip =>
        ({
          ...trip,
          createdAt: new Date(trip.createdAt),
          updatedAt: new Date(trip.updatedAt),
        }) satisfies TransportTrip
    );
  }

  async getStopStatuses(
    agencyId: string,
    stopId: number
  ): Promise<TransportStopStatus[]> {
    const stopStatuses = await networkClient.get<TransportStopStatusAxios[]>(
      `/${TRANSPORT_PREFIX}/agencies/${agencyId}/stopStatuses/${stopId}`
    );
    return stopStatuses.map(
      stopStatuses =>
        ({
          ...stopStatuses,
          createdAt: new Date(stopStatuses.createdAt),
          updatedAt: new Date(stopStatuses.updatedAt),
        }) satisfies TransportStopStatus
    );
  }

  async refreshStopStatuses(agencyId: string): Promise<void> {
    await networkClient.post(
      `/${TRANSPORT_PREFIX}/agencies/${agencyId}/stopStatuses/refresh`,
      {}
    );
  }
}

export const transportClient = new TransportClient();
