import classNames from "classnames";
import {
  BusFrontIcon,
  CableCarIcon,
  MapPinIcon,
  RotateCwIcon,
  ShipIcon,
  ShoppingCartIcon,
  TrainFrontIcon,
  TrainFrontTunnelIcon,
  TramFrontIcon,
} from "lucide-react";
import React, { useMemo, useState } from "react";
import { Button } from "../common/Button";
import { SearchBox } from "../common/SearchBox";
import { Text } from "../common/Text";
import { useIsUserAdmin } from "../user/UserContext";
import {
  useTransportMetadataContext,
  type TransportStop,
} from "./Transport/TransportMetadataContext";
import {
  StopStatusDetails,
  useTransportStopStatusContext,
  useUpdateStopIdSearchParams,
} from "./Transport/TransportStopStatusContext";
import { WidgetHeader } from "./WidgetHeader";

export default function PublicTransport() {
  const { stops, routes, trips } = useTransportMetadataContext();
  const {
    selectedStopId,
    setSelectedStopId,
    stopStatuses,
    refreshStopStatuses,
  } = useTransportStopStatusContext();
  const selectedStop = stops.get(selectedStopId)!;

  const groupedStops = useMemo(() => {
    const groups = new Map<string, TransportStop[]>();
    stops.forEach(stop => {
      if (!groups.has(stop.stopName)) {
        groups.set(stop.stopName, []);
      }
      groups.get(stop.stopName)!.push(stop);
    });
    return groups;
  }, [stops]);
  const stopsNames = useMemo(
    () => Array.from(groupedStops.keys()),
    [groupedStops]
  );
  const isUserAdmin = useIsUserAdmin();
  const [searchResults, setSearchResults] = useState<string[]>(stopsNames);
  const stopsWithSameName =
    groupedStops.get(selectedStop?.stopName ?? "") ?? [];

  useUpdateStopIdSearchParams();

  return (
    <div
      className={classNames(
        "flex flex-col",
        "col-span-full md:col-span-4 row-span-3",
        "md:bg-white border border-gray-200 hover:border-green-700/30 rounded-md duration-300 group/header"
      )}
    >
      <div
        className={classNames(
          "flex flex-col",
          "shrink-0 p-2 border-b rounded-t-md",
          "border-gray-200 group-hover/header:bg-green-50 group-hover/header:border-green-700/30 transition-colors"
        )}
      >
        <div
          className={classNames("flex flex-row justify-between items-center")}
        >
          <WidgetHeader
            icon={<BusFrontIcon className="w-4 h-4 text-green-950" />}
            color="text-green-950"
          >
            Transport Public
          </WidgetHeader>
          {isUserAdmin ? (
            <Button
              icon={<RotateCwIcon className="h-4 w-4 shrink-0 " />}
              value=""
              variant="outlineLight"
              size="small"
              onClick={refreshStopStatuses}
              className="bg-white"
            />
          ) : null}
        </div>
        <div className="flex flex-row gap-2">
          <SearchBox
            inputIcon={
              <MapPinIcon className="h-4 w-4 shrink-0 text-secondary" />
            }
            emptyResultsMessage="Nu s-au gasit statii."
            searchBoxPlaceholder="Cauta statie..."
            results={searchResults.map(stopName => ({
              displayName: stopName,
              id: groupedStops.get(stopName)![0].stopId!,
            }))}
            selectedValue={{
              id: selectedStop?.stopId ?? 7,
              displayName: selectedStop?.stopName ?? "Piata Unirii",
            }}
            onValueChange={(newSearchValue: string) => {
              const regex = new RegExp(
                newSearchValue
                  .split("")
                  .map(char => `${char}`)
                  .join(".*"),
                "i"
              );
              const results = stopsNames.filter(stopName =>
                regex.test(
                  stopName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                )
              );
              setSearchResults(results);
            }}
            onValueSelection={(stopId: number) => {
              setSelectedStopId(stopId);
            }}
          />
          {stopsWithSameName.map(stop => (
            <Button
              aria-selected={selectedStopId === stop.stopId}
              variant="outlineLight"
              key={stop.stopId}
              value={stop.alias}
              onClick={() => setSelectedStopId(stop.stopId)}
            />
          ))}
        </div>
      </div>
      <div className="grid on-transport-grid w-full gap-2 px-2 border-b font-semibold">
        <div></div>
        <Text className="justify-self-center font-mon" color="secondary">
          Nr
        </Text>
        <Text color="secondary">Directie</Text>
        <Text className="justify-self-end" color="secondary">
          Estimare
        </Text>
      </div>
      <div className="flex flex-col gap-1 w-full overflow-y-scroll py-2">
        {stopStatuses.map((stopStatus, idx) => {
          const trip = trips.get(stopStatus.tripId);
          if (trip == null) {
            // console.log("Trip not found", stopStatus);
            return null;
          }
          const route = routes.get(trip.routeId!);
          const vehicleStatus = stopStatus.arrivalStatus;
          if (vehicleStatus === "Unknown") {
            return null;
          }

          const vehicleType = stopStatus.vehicleType;
          return (
            <div
              key={idx}
              className={classNames(
                "grid on-transport-grid gap-2 px-2 py-0.5",
                {
                  "bg-gray-100": idx % 2 !== 0,
                }
              )}
            >
              <div className={classNames("text-secondary justify-self-center")}>
                {vehicleType === 0 ? (
                  <TramFrontIcon />
                ) : vehicleType === 1 ? (
                  <TrainFrontTunnelIcon />
                ) : vehicleType === 2 ? (
                  <TrainFrontIcon />
                ) : vehicleType == 3 ? (
                  <BusFrontIcon />
                ) : vehicleType === 4 ? (
                  <ShipIcon />
                ) : vehicleType === 5 ? (
                  <CableCarIcon />
                ) : (
                  <ShoppingCartIcon />
                )}
              </div>
              <div
                className={classNames(
                  "font-semibold text-gray-700 justify-self-center font-mono"
                )}
              >
                {route?.routeShortName}
              </div>
              <div className={classNames("flex-1", {})}>
                {trip?.tripHeadsign}
              </div>
              <div
                className={classNames(
                  "flex flex-row justify-self-end gap-1 shrink-0"
                )}
              >
                {stopStatus.details === StopStatusDetails.NewOnRoute ? (
                  <div className="shrink-0 text-secondary text-sm self-center">
                    (la capat)
                  </div>
                ) : null}
                <div className="font-semibold text-gray-700 shrink-0">
                  {vehicleStatus === "Arrived"
                    ? "In statie"
                    : vehicleStatus === "Arriving"
                      ? "Ajunge"
                      : `${stopStatus.remainingStops} statii`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
