import { networkClient } from "../../client/networkClient";
import { OnEvent } from "./OnEvent";

export type CreateOnEventRequestPayload = {
  name: string;
  oneLine: string;
  description: string;
  duration?: number;
  startDate: Date;
  endDate?: Date;
  address: string;
  locationOnMap?: string;
  links: Array<{ category: string; url: string }>;
  status: "Draft" | "Published" | "Archived";
  organizerId?: string | undefined;
  // hardcoded values
  city: string;
  access: "Public" | "Private";
  accessMethod: "Invite" | "Registration" | "Public" | "Ticket";
  accessRequest?: string[];
  categories: Array<string>;
  instance: "Instance" | "Series";
};

export type EventSearchFilters = {
  query?: string;
  organizerId?: string;
  afterDate?: Date;
  beforeDate?: Date;
  limit?: number;
  skip?: number;
  order?: "asc" | "desc";
};

export const EVENT_PREFIX = "events";

class EventsClient {
  public async createEvent(event: CreateOnEventRequestPayload) {
    return networkClient.post<CreateOnEventRequestPayload, OnEvent>(
      `/${EVENT_PREFIX}`,
      event
    );
  }

  public async updateEvent(
    eventId: string,
    event: CreateOnEventRequestPayload
  ) {
    return networkClient.put<CreateOnEventRequestPayload, OnEvent>(
      `/${EVENT_PREFIX}/${eventId}`,
      event
    );
  }

  public async getEvents() {
    return networkClient.get<OnEvent[]>(`/${EVENT_PREFIX}`);
  }

  public async searchEvents(filters: EventSearchFilters) {
    return networkClient.post<
      EventSearchFilters,
      { events: OnEvent[]; hasMore: boolean }
    >(`/${EVENT_PREFIX}/search`, filters);
  }

  public async getEvent(eventId: string) {
    return networkClient.get<OnEvent>(`/${EVENT_PREFIX}/${eventId}`);
  }

  public async deleteEvent(eventId: string) {
    return networkClient.delete(`/${EVENT_PREFIX}/${eventId}`);
  }

  public async uploadMedia(eventId: string, formData: FormData) {
    return networkClient.post<FormData, OnEvent>(
      `/${EVENT_PREFIX}/${eventId}/media`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  public async deleteMedia(eventId: string, mediaIds: string[]) {
    return networkClient.put<{ mediaIds: string[] }, OnEvent>(
      `/${EVENT_PREFIX}/${eventId}/media/delete`,
      { mediaIds }
    );
  }
}

export const eventsClient = new EventsClient();
