import { MapPinHouseIcon } from "lucide-react";
import React from "react";
import { WidgetHeader } from "./WidgetHeader";

export default function LocalProducers() {
  return (
    <div className="col-span-full md:col-span-6 row-span-3 bg-purple-50/20 border-purple-200 md:border-gray-200 md:bg-white hover:bg-purple-50/20 hover:border-purple-200 border rounded-md on-widget">
      <WidgetHeader icon={<MapPinHouseIcon className="w-4 h-4" />}>
        Producatori Locali
      </WidgetHeader>
    </div>
  );
}
