import { networkClient } from "../../client/networkClient";
import type { OnMedia } from "../Events/MediaView";

export enum DayHighlightStatus {
  Published = "Published",
  Draft = "Draft",
  Archived = "Archived",
}

export type DayHighlight = {
  // identifier
  date: string;
  id: string;

  // editable
  media: OnMedia[];
  title: string;
  description: string;
  content: string;
  order: number;
  status: DayHighlightStatus;

  // automated
  // updatedAt: Date;
  // createdAt: Date;
};

export type CreateDayHighlightRequestPayload = Omit<
  DayHighlight,
  "id" | "createdAt" | "updatedAt" | "order" | "media"
>;

export const DAY_HIGHLIGHT_PREFIX = "highlights";

class HighlightsClient {
  public async createHighlight(highlight: CreateDayHighlightRequestPayload) {
    return networkClient.post<CreateDayHighlightRequestPayload, DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}`,
      highlight
    );
  }

  public async updateHighlight(
    highlightId: string,
    highlight: CreateDayHighlightRequestPayload
  ) {
    return networkClient.put<CreateDayHighlightRequestPayload, DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}/${highlightId}`,
      highlight
    );
  }

  public async deleteHighlight(highlightId: string) {
    return networkClient.delete(`/${DAY_HIGHLIGHT_PREFIX}/${highlightId}`);
  }

  public async getHighlights(date: string) {
    return await networkClient.get<DayHighlight[]>(
      `/${DAY_HIGHLIGHT_PREFIX}/day/${date}`
    );
  }

  public async getHighlight(highlightId: string) {
    return networkClient.get<DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}/${highlightId}`
    );
  }

  public async uploadMedia(highlightId: string, formData: FormData) {
    return networkClient.post<FormData, DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}/${highlightId}/media`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  public async deleteMedia(highlightId: string, mediaIds: string[]) {
    return networkClient.put<{ mediaIds: string[] }, DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}/${highlightId}/media/delete`,
      { mediaIds }
    );
  }
}

export const highlightsClient = new HighlightsClient();
