import * as React from "react";

const sizeStyles = {
  large: "text-lg",
  default: "h-9",
  small: "h-7 text-sm",
  icon: "h-9 w-9",
  none: "",
};

const variantStyles = {
  none: "",
  default: "text-white bg-primary hover:shadow hover:bg-primary-hover",
  destructive:
    "text-white bg-destructive hover:bg-destructive-hover hover:shadow",
  warning: "text-white bg-warning hover:bg-warning-hover",
  secondary: "text-white bg-secondary hover:bg-secondary-hover/90",
  link: "text-primary underline-offset-4 hover:underline",
  outlineLight:
    "bg-white text-black-600 hover:text-black hover:bg-gray-100 aria-selected:bg-gray-200 hover:shadow border",
  flatLight:
    "bg-white text-gray-800 hover:text-black hover:bg-gray-100 aria-selected:bg-gray-200 hover:shadow",
  flatDark:
    "text-gray-100  hover:text-white hover:bg-gray-100/20 aria-selected:bg-gray-200/20 hover:shadow",
  outlineDark:
    "text-white bg-white/20 hover:bg-gray-100/20 aria-selected:bg-gray-200/20 hover:shadow",
};

const paddingStyles: Record<string, string> = {
  default: "px-3 py-2",
  "default,link": "py-2",
  small: "px-2 py-1",
  large: "px-2 py-1",
  "small,link": "py-1",
  icon: "",
};

function getPaddingClasses({
  size,
  variant,
}: {
  size: string;
  variant: string;
}): string {
  const key = [size, variant].join(",");
  return paddingStyles[key] ?? paddingStyles[size] ?? "";
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof variantStyles;
  size?: keyof typeof sizeStyles;
  icon?: React.ReactNode;
}

export function getButtonClass({
  className,
  variant = "default",
  size = "default",
}: ButtonProps): string {
  const baseClass =
    "inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 gap-1";

  const computedClassName = [
    baseClass,
    sizeStyles[size],
    variantStyles[variant],
    getPaddingClasses({ size, variant }),
    className,
  ].join(" ");

  return computedClassName;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant = "default", size = "default", icon, ...props },
    ref
  ) => {
    const computedClassName = getButtonClass({ className, variant, size });

    return (
      <button type="button" className={computedClassName} ref={ref} {...props}>
        {icon} {props.value}
      </button>
    );
  }
);
Button.displayName = "Button";
