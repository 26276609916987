import * as React from "react";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hasBorder?: boolean;
  hasShadow?: boolean;
  padding?: string;
  height?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      hasBorder = true,
      hasShadow = true,
      height,
      padding,
      ...props
    },
    ref
  ) => {
    return (
      <input
        type={type}
        className={[
          "flex rounded-md bg-white transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-secondary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          height ?? "h-9",
          padding ?? "py-1 px-3",
          hasBorder ? "border border-input" : "border-0",
          hasShadow ? "shadow-sm" : "",
          className,
        ].join(" ")}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
