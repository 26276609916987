"use client";

import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { DayPicker } from "react-day-picker";
import { ro } from "date-fns/locale";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={["p-3", className].join(" ")}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        nav_button_previous:
          "absolute left-1 flex justify-center items-center hover:bg-gray-100 rounded-md",
        nav_button_next:
          "absolute right-1 flex justify-center items-center hover:bg-gray-100 rounded-md",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell: "text-secondary rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: [
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md",
        ].join(" "),
        day: "h-8 w-8 p-0 font-normal aria-selected:opacity-100 hover:bg-blue-100 rounded-md",
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-primary-foreground bg-blue-500 text-white hover:bg-blue-300 hover:text-black rounded-md",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-secondary opacity-50  aria-selected:bg-accent/50 aria-selected:text-secondary aria-selected:opacity-30",
        day_disabled: "text-secondary opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      locale={ro}
      components={{
        IconLeft: ({ ...props }) => (
          <ChevronLeftIcon className="h-4 w-4" {...props} />
        ),
        IconRight: ({ ...props }) => (
          <ChevronRightIcon className="h-4 w-4" {...props} />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
