import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import { useUserContext } from "./user/UserContext";
import { Events } from "./widgets/Events";
import LocalProducers from "./widgets/LocalProducers";
import LocationAnchor from "./widgets/LocationAnchor";
import PublicAnnouncements from "./widgets/PublicAnnouncements";
import PublicTransport from "./widgets/PublicTransport";
import Search from "./widgets/Search";
import TodayHighlights from "./widgets/TodayHighlights";
import Weather from "./widgets/Weather/Weather";

export function HomePage() {
  return (
    <div className="grid grid-cols-12 w-full auto-rows-[5.5rem] gap-2 px-2 pt-2 pb-4">
      <Events />
      <PublicTransport />
      <TodayHighlights />
      <PublicAnnouncements />
      <LocalProducers />
    </div>
  );
}

export function HomeLayout() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const { user } = useUserContext();
  const toggleSidebar = useCallback(() => {
    setSidebarVisible(!isSidebarVisible);
  }, [isSidebarVisible]);

  const header = useMemo(() => {
    return (
      <div
        className={classNames(
          // "h-[6.5rem] md:h-[5.5rem]",
          user == null
            ? "grid-rows-header-mobile-1"
            : "grid-rows-header-mobile-2",
          "md:grid-rows-header",
          "grid grid-cols-12 w-full row-span-1 bg-slate-700"
        )}
      >
        <LocationAnchor
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        />
        <Search />
        <Weather />
      </div>
    );
  }, [isSidebarVisible, toggleSidebar, user]);

  return (
    <>
      <Sidebar
        isSidebarVisible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={classNames(
          "gap-2 h-full overflow-y-auto transition-all duration-300",
          isSidebarVisible ? "ml-36" : ""
        )}
      >
        {header}
        <Outlet />
      </div>
    </>
  );
}
