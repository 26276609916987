import React from "react";
import { Badge, pillColorToClass } from "./Badge";
import { textColors } from "./Text";

export enum OnStatus {
  Draft = "Draft",
  Published = "Published",
  Deleted = "Deleted",
}

type OnStatusBadgeProps = {
  status: OnStatus;
};

const getColorFromStatus = (status: OnStatus) => {
  return status === "Draft" ? "amber" : status === "Published" ? "blue" : "red";
};

export function OnStatusBadge({ status }: OnStatusBadgeProps) {
  return <Badge content={status} color={getColorFromStatus(status)} />;
}

export function getPillColorFromStatus(status: OnStatus) {
  const color = getColorFromStatus(status);
  return pillColorToClass[color];
}

export function getPilTextColorFromStatus(status: OnStatus) {
  const color = getColorFromStatus(status);
  return textColors[color];
}
